export const routes = [
    {
        path: '/appointments',
        name: 'appointments.browse',
        component: () => import(/* webpackChunkName: "AppointmentsBrowse" */ '@/views/app/AppointmentsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/appointments/:id/clone',
        name: 'appointments.clone',
        component: () => import(/* webpackChunkName: "AppointmentsActions" */ '@/views/app/AppointmentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/appointments/create',
        name: 'appointments.create',
        component: () => import(/* webpackChunkName: "AppointmentsActions" */ '@/views/app/AppointmentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/appointments/:id/edit',
        name: 'appointments.edit',
        component: () => import(/* webpackChunkName: "AppointmentsActions" */ '@/views/app/AppointmentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/appointments/:id/delete',
        name: 'appointments.delete',
        component: () => import(/* webpackChunkName: "AppointmentsActions" */ '@/views/app/AppointmentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/appointments/:id/show',
        name: 'appointments.show',
        component: () => import(/* webpackChunkName: "AppointmentsActions" */ '@/views/app/AppointmentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]